<template>
  <v-container>
    <HyphenDatatable
    :blockText="blockTextProp"
    :headers="headers"
    :config="{resource:'admin', actionUrl: '/admin/create', title: 'Admins', enabledActions: [ 'block', 'search']}"
  />
  </v-container>
 
<!--  <v-container fluid tag="section">-->
<!--    <v-data-table-->
<!--      :headers="headers"-->
<!--      :items="users"-->
<!--      sort-by="name"-->
<!--      class="elevation-1"-->
<!--      @click:row="handleClick"-->
<!--      :options.sync="options"-->
<!--      :server-items-length="count"-->
<!--    :items-per-page="10"-->
<!--    :loading="loading"-->
<!--    >-->
<!--      <template v-slot:top>-->
<!--        <v-toolbar flat>-->
<!--          <v-toolbar-title>Admin</v-toolbar-title>-->
<!--          <v-divider class="mx-4" inset vertical></v-divider>-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn color="primary" dark class="mb-2" @click="newUser">-->
<!--            New-->
<!--          </v-btn>-->
<!--          <v-dialog v-model="dialog" max-width="800px"> </v-dialog>-->
<!--          <v-dialog v-model="dialogDelete" max-width="500px">-->
<!--            <v-card>-->
<!--              <v-card-text>-->
<!--                <h4 class="display-2">-->
<!--                  Are you sure you want to delete this user?-->
<!--                </h4>-->
<!--              </v-card-text>-->

<!--              <v-card-actions>-->
<!--                <v-spacer></v-spacer>-->
<!--                <v-btn color="blue darken-1" text @click="closeDelete"-->
<!--                  >Cancel</v-btn-->
<!--                >-->
<!--                <v-btn color="blue darken-1" text @click="deleteItemConfirm"-->
<!--                  >OK</v-btn-->
<!--                >-->
<!--                <v-spacer></v-spacer>-->
<!--              </v-card-actions>-->
<!--            </v-card>-->
<!--          </v-dialog>-->

<!--          <v-dialog v-model="dialogBlock" max-width="550px" height="600px">-->
<!--            <v-card>-->
<!--              <v-card-text>-->
<!--                <h4 class="display-2">-->
<!--                  Are you sure you want to-->
<!--                  {{ blockedItem.isDisabled ? "Unblock" : "Block" }} this user?-->
<!--                </h4>-->
<!--              </v-card-text>-->
<!--              <v-container v-if="!blockedItem.isDisabled">-->
<!--                <v-row>-->
<!--                  <v-col cols="12">-->
<!--                    <v-text-field-->
<!--                      label="Remarks"-->
<!--                      v-model="blockRemarks"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-container>-->
<!--              <v-card-actions>-->
<!--                <v-spacer></v-spacer>-->

<!--                <v-btn color="blue darken-1" text @click="closeBlock"-->
<!--                  >Cancel</v-btn-->
<!--                >-->
<!--                <v-btn color="blue darken-1" text @click="blockItemConfirm"-->
<!--                  >OK</v-btn-->
<!--                >-->
<!--                <v-spacer></v-spacer>-->
<!--              </v-card-actions>-->
<!--            </v-card>-->
<!--          </v-dialog>-->
<!--        </v-toolbar>-->
<!--        <app-filter @filter-change="getUsers"/>-->
<!--      </template>-->
<!--      <template v-slot:item.actions="{ item }">-->
<!--        <v-icon small class="mr-2" @click.stop="editItem(item)">-->
<!--          mdi-pencil-->
<!--        </v-icon>-->
<!--        <v-icon small @click.stop="deleteItem(item)" class="mr-2">-->
<!--          mdi-delete-->
<!--        </v-icon>-->

<!--        <v-tooltip bottom>-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-icon-->
<!--              small-->
<!--              @click.stop="blockItem(item)"-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--              :class="item.isDisabled == true ? 'red&#45;&#45;text lighten-1' : ''"-->
<!--            >-->
<!--              mdi-block-helper-->
<!--            </v-icon>-->
<!--          </template>-->
<!--          <span>{{ item.isDisabled == true ? "Unblock" : "Block" }}</span>-->
<!--        </v-tooltip>-->
<!--      </template>-->
<!--      <template v-slot:no-data> No Data </template>-->
<!--    </v-data-table>-->
<!--  </v-container>-->
</template>


<script>
import HyphenDatatable from "@/views/dashboard/component/HyphenDatatable.vue";

export default {
  components: {HyphenDatatable},
  data: () => ({
    blockTextProp: "admin",
    dialog: false,
    dialogDelete: false,
    dialogBlock: false,
    blockedItem: {},
    blockRemarks: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Profile", value: "profile" },
      // { text: 'Actions', value: 'actions', sortable: false },
    ],
    users: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    count: null,
    loading:false,
    options:{},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options:{
       handler () {
          this.getUsers()
        },
        deep: true,
    }

  },

  methods: {
    newUser() {
      this.$router.push({ path: "/admin/create" });
    },
    handleClick(args) {
      if (this.hasRole("Users", "Edit User")) {
        console.log(args);
        this.$router.push({ path: "/admin/" + args.clientId });
      }
    },
    getUsers($event) {
      if($event?.search){
        this.options.search = $event.search
      }
      this.formatFilter
      if($event?.isDeleted){
        this.options.isDeleted = $event.isDeleted
      }
      this.loading = true;
      this.$Auth
        .getAdmin(this.options)
        .then((data) => {

          this.users = data.data.data.list;
          this.count = data.data.data.count;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let deleteItem = {
        blockDeleteUserId: this.users[this.editedIndex].id,
        remarks: "DELETED",
      };

      this.$API
        .deleteAdmin(deleteItem)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });

      this.admin.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    blockItem(item) {
      this.blockedItem = item;
      this.dialogBlock = true;
    },

    blockItemConfirm() {
      let blockedIndex = this.users.indexOf(this.blockedItem);
      let blockDetails = {
        blockDeleteUserId: this.blockedItem.id,
        remarks: this.blockRemarks,
      };

      if (this.blockedItem.isDisabled) {
        this.$API
          .unblockAdmin(blockDetails)
          .then((data) => {
            this.admin[blockedIndex].isDisabled = false;
            this.closeBlock();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$API
          .blockAdmin(blockDetails)
          .then((data) => {
            this.admin[blockedIndex].isDisabled = true;
            this.closeBlock();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    closeUnblock() {
      this.dialogUnblock = false;
    },
    closeBlock() {
      this.dialogBlock = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  mounted() {
    // this.getUsers();
  },
};
</script>

<style scoped>
</style>
